import React from 'react';
import PropTypes from 'prop-types';
import Form from '@components/domain/form';

const StaffProfile = ({ courseId, data, profile, setProfile }) => {
  return (
    <Form
      header={data.introduction}
      courseId={courseId}
      questions={data.questions}
      values={profile}
      onChange={setProfile}
      userType="instructor"
    />
  );
};

export default StaffProfile;

StaffProfile.propTypes = {
  courseId: PropTypes.string,
  data: PropTypes.shape({
    introduction: PropTypes.string,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        fields: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
            type: PropTypes.string,
            options: PropTypes.arrayOf(PropTypes.string),
            disabled: PropTypes.bool,
            required: PropTypes.bool,
            max: PropTypes.number
          })
        )
      })
    )
  }),
  profile: PropTypes.object,
  setProfile: PropTypes.func
};
