import React, { useEffect, useState } from 'react';
import Layout from '@components/domain/shared/layout';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { graphql, Link } from "gatsby";
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Description from '@components/domain/course-details/description';
import EmptySpace from '@components/ui/empty-space';
import PropTypes from 'prop-types';
import Spacing from '@components/ui/spacing';
import styles from '@styles/templates/curso-candidatura.module.scss';
import Spinner from '@components/ui/spinner';
import { useAuth0 } from "@auth0/auth0-react";
import {HttpClient} from "@utilities/http-client";
import {validate} from '@components/domain/form';
import Submission, {NotAllowedError} from "@components/domain/form/submission";
import StaffProfile from "@components/domain/course-staff-profile";
import SEO from "@components/ui/seo";

const CursoPerfilEquipa = (props) => {
    const {course_slug, iteration_name} = props.params;
    const [ user, setUser ] = useState();
    const [ course, setCourse ] = useState();

    const getResourcePath = () => `auth/staff_profile/${course.id}`;

    const [ profile, setProfile ] = useState({});
    const [ errors, setErrors ] = useState([]);
    const [ submissionError, setSubmissionError ] = useState();
    const [ hasProfile, setHasProfile ] = useState();
    const [ submitted, setSubmitted ] = useState(false);
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const breakpoints = useBreakpoint();

    const httpClient = HttpClient(getAccessTokenSilently);

    // load course
    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        (async () => {
            const response = await httpClient(`iterations/${course_slug}/${iteration_name}`);
            if (response.ok) {
                setCourse(await response.json());
            }
        })();
    }, [isAuthenticated]);

    // load user profile
    useEffect(() => {
        if (!course) {
            return;
        }

        (async () => {
            const response = await httpClient("auth/");
            if (!response.ok) {
                console.error("An error has occured.", response.status);
                return;
            }

            const userJson = await response.json();
            setUser(userJson);

            const staffProfileResponse = await httpClient(getResourcePath());

            if (!staffProfileResponse.ok) {
                console.error("An error has occured.", staffProfileResponse.status);
                setHasProfile(false);
                return;
            }

            const staffProfileJson = await staffProfileResponse.json();

            // init profile form with existing data
            if (staffProfileJson) {
                setProfile({
                    ...staffProfileJson, email: userJson.email
                });
                setHasProfile(true);
            } else
                setHasProfile(false);
        })();
    }, [course]);

    const onSubmit = () => {
        let errors = [];

        props.data.staff_form.questions.map(group => {
            group.fields.map(field => {
                errors = [ ...errors, ...validate(group, field, profile[field.id]) ];
            });
        });

        setErrors(errors);

        if (errors.length === 0 && !submitted) {
            (async () => {
                try {
                    // eslint-disable-next-line no-unused-vars
                    const filtered_profile = (({email, ...others}) => ({...others}))(profile);

                    const response = await httpClient(getResourcePath(), {
                        method: "PUT",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(filtered_profile)
                    });

                    setSubmitted(true);

                    if (!response.ok) {
                        console.error("An error has occured.", response.status);
                        const errorJson = await response.json();
                        setSubmissionError(errorJson.message || true);
                        return;
                    }

                    setSubmissionError(false);
                } catch (error) {
                    console.error("An error has occured.", error);
                }
            })();
        }
    };

    return (
        <Layout extraFooterPadding={breakpoints.mobile ? 200 : 0}>
            <SEO title="Perfil Equipa" />

            <EmptySpace desktop={{ margin: 120 }} mobile={{ margin: 100 }} />
            {course &&
            <div className={styles.container}>
                <EmptySpace desktop={{margin: 30}} mobile={{margin: 20}}/>
                <Spacing>
                    <div className={styles.metadata}>
                        <section className={styles.overview}>
                            <Description
                                title="Perfil Equipa"
                                courseName={course.course.name + " " + course.iteration_name}
                            />
                        </section>
                    </div>
                </Spacing>
                <Spacing>
                    {!user && <Spinner/>}
                    {user && hasProfile === false &&
                    <>
                        <NotAllowedError
                            error="Erro ao carregar a informação pedida."
                            errorDescription="De certeza que é suposto estares nesta página?"
                        />
                        <div className={styles.controls}>
                            <Link to={'/'}>Voltar à página anterior</Link>
                        </div>
                    </>
                    }
                    {user && hasProfile &&
                    <>
                        {!submitted &&
                        <StaffProfile
                            courseId={course.id}
                            data={props.data.staff_form || []}
                            profile={profile}
                            setProfile={setProfile}
                        />
                        }
                        {submitted && <Submission
                            loading={submissionError === undefined}
                            error={submissionError}
                            errorDescription="Ocorreu um erro ao submeter o formulário."
                            successDescription="A tua submissão será validada e posteriormente ficará disponível na página do curso!"
                            successMessage="Resposta submetida com sucesso!"
                        />}
                        {errors.length > 0 &&
                        <div className={styles.errors}>
                            <div className={styles.title}>Erros de validação:</div>
                            <ul>
                                {errors.map((error, i) =>
                                    <li key={i}>{error.message}</li>
                                )}
                            </ul>
                        </div>
                        }
                        {!submitted &&
                        <>
                            <p><strong>NOTA: </strong>Caso toda a informa&ccedil;&atilde;o acima esteja correta, por
                                favor n&atilde;o faças uma nova submiss&atilde;o.</p>
                            <div className={styles.controls}>
                                <a className={styles.continue} onClick={onSubmit}>Submeter</a>
                            </div>
                        </>
                        }
                    </>
                    }
                </Spacing>
            </div>
            }
            {!course &&
                <Spinner />
            }
            <EmptySpace desktop={{ margin: 120 }} mobile={{ margin: 100 }} />
        </Layout>
    );
};

export default withAuthenticationRequired(CursoPerfilEquipa);


export const pageQuery = graphql`
query CourseStaffProfileQuery {
  staff_form: staffProfileJson {
    questions {
      title
      fields {
        id
        label
        description
        type
        required
        disabled
      }
    }
    introduction
  }
}
`;

CursoPerfilEquipa.propTypes = {
    path: PropTypes.string,
    data: PropTypes.shape({
        staff_form: PropTypes.shape({
            introduction: PropTypes.string,
            questions: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string,
                    fields: PropTypes.arrayOf(
                        PropTypes.shape({
                            id: PropTypes.string,
                            label: PropTypes.string,
                            type: PropTypes.string,
                            options: PropTypes.arrayOf(PropTypes.string),
                            disabled: PropTypes.bool,
                            required: PropTypes.bool,
                            max: PropTypes.number
                        })
                    )
                })
            )
        })
    })
};